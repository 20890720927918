import { useRoutes, navigate} from "hookrouter";
import { useEffect } from "react";
import LoadMainThings from "./util/LoadMainThings";
import { routes } from "./util/routes";
import {useSelector} from 'react-redux';

const showDebugInfo=localStorage.getItem('debug')?true:false

function AppRoute(props) {

  const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);

    const routeResult = useRoutes(routes);

    function recuperaemail(){
      if (props.email){
        return props.email
      }
      if (emailloggato){
        return emailloggato;
      }
      if (localStorage.getItem("email")){
        return localStorage.getItem("email")
      }
    }

    useEffect(() =>{
      if(props.initialpage){
        console.log(props.initialpage)
        navigate(props.initialpage,false);
      }
    },[])

  return (
    <span>
      <LoadMainThings toolbar={showDebugInfo} 
      showDebugInfo={showDebugInfo} email={recuperaemail()} 
      nome={props.nome} cognome={props.cognome} 
      telefono={props.telefono} idselezionata={props.id} 
      t={props.t}/>
      {routeResult}
    </span>
  );
}

export default AppRoute;
