import React from "react";

import Login from "../login/Login";
import InserimentoCodice from "../utente/InserimentoCOdice";
import NuovoUtente from "../utente/NuovoUtente";
import LoginUtil from "../login/LoginUtil";

export const routes = {
   
    "*/annotatiou-login/": () => <Login />,
    "*/annotatiou-utillogin/": () => <LoginUtil />,
    "*/annotatiou-inscode/": () => <InserimentoCodice />,
    "*/annotatiou-insutente/": () => <NuovoUtente />,
  };