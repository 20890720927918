import React from "react";

import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { loginapp } from "../DAO/anagraficaDAO";
import { useRoutes, navigate} from "hookrouter";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

import {
    LoginSocialGoogle,
    LoginSocialFacebook,

  } from 'reactjs-social-login';
  
  import {
    FacebookLoginButton,
    GoogleLoginButton,
  } from 'react-social-login-buttons';
import { useCallback } from "react";
import { setEmailLoggato, setanagrafica, settoken } from "../STORE/reducers/gestoreUtente";
import gettoken from "../DAO/tokenDAO";
import { getpalestreassociate } from "../DAO/palestraDAO";

const REDIRECT_URI = window.location.href;

export default function Login(props){
  const email = useSelector(state => state.gestoreUtente.emailloggato);

    const [emaillogin, setemaillogin] = useState('')
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)
    const dispatch = useDispatch();

    const [captchatoken, setcaptchatoken] = useState()

    function entra(){
        async function loginforapp() {
            const anagesistente = await loginapp(emaillogin, captchatoken);
            console.log(anagesistente)
            if (anagesistente){
              localStorage.setItem('emaildaconfermare',emaillogin);
               //setshowcodice(true)
              navigate('/annotatiou-inscode/');
            }
            else{
              alert('Email non trovata')
            }
          }
          if (emaillogin){
            loginforapp();
          }
    }

    function confermaSocialLogin(emailsocial){
        async function sociallogin() {
            if (emailsocial){
                const result = await gettoken(emailsocial,'')
                
                window.$t=result
                dispatch(settoken(result))
            
        const palestre = await getpalestreassociate(emailsocial,'',result);
            if (palestre&&Array.isArray(palestre)&&palestre.length>0){
                dispatch(setanagrafica(null))
                localStorage.setItem('idanag',null);
                
                dispatch(setEmailLoggato(emailsocial));
                localStorage.setItem("email",emailsocial)
                localStorage.removeItem("emaildaconfermare")
                window.location.href='/'
            }
            else{
                alert('Confermi la registrazione di '+emailsocial+'?');
            }
          }
          else {alert('No email specified after social login');}
        }
        sociallogin()
    }
  
    const onLoginStart = useCallback(() => {
      console.log('login start')
    }, [])
  
    const onLogoutSuccess = useCallback(() => {
      dispatch(setEmailLoggato(null))
      alert('logout success')
    }, [])

    return (
        <div>
            {
            !localStorage.getItem('email')&&!email&&
                <div>
<LoginSocialFacebook
          appId="410867880057409"
          fieldsProfile={
            'first_name,last_name,middle_name,name,email'
          }
          onLoginStart={onLoginStart}
          onLogoutSuccess={onLogoutSuccess}
          redirect_uri="https://annotatio.online/"
          onResolve={( provider, data ) => {
            console.log(provider.provider,"provider")
            console.log(provider.data, "data")
            confermaSocialLogin(provider.data.email);
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <FacebookLoginButton />
        </LoginSocialFacebook>

        <LoginSocialGoogle
          client_id="1008305227109-rahajufb101r59qvk7a8np7jh9hpq3mi.apps.googleusercontent.com"
          onLoginStart={onLoginStart}
scope="https://www.googleapis.com/auth/userinfo.profile"
          onResolve={( provider, data ) => {
            console.log(provider.provider,"provider")
            console.log(provider.data, "data")
            confermaSocialLogin(provider.data.email);
            
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <GoogleLoginButton />
        </LoginSocialGoogle>

        <hr />
        Oppure Login con la tua email
        <br />
        <GoogleReCaptchaProvider reCaptchaKey="6LeLySYaAAAAAAkxF-AI4qDkwEqoYqhfPlwA88ut">
          <div>
            <div>
              <input type="text" name="emaillogin" value={emaillogin} onChange={(e) => setemaillogin(e.target.value)} />
              <button onClick={entra}>Login</button>
            </div>
                <GoogleReCaptcha
                        onVerify={token => {
                          setcaptchatoken(token);
                        }}
                  />
          </div>
            </GoogleReCaptchaProvider>
            </div>
            }
        </div>
    )
          }