import React from "react";
import '../util/config'
import { useState } from "react";
import {useDispatch} from 'react-redux';

import {
  LoginSocialGoogle,
  LoginSocialFacebook,

} from 'reactjs-social-login';

import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons';
import { useCallback } from "react";
import { setEmailLoggato, setanagrafica, settoken } from "../STORE/reducers/gestoreUtente";
import { insutente } from "../DAO/utenteDAO";
import gettoken from "../DAO/tokenDAO";

export default function NuovoUtente (props){
  const [id, setid] = useState(0)
  const [name, setname] = useState(0)
 
  const [imageUrl, setimageUrl] = useState('')
  const [address, setaddress] = useState('')
  const [address2, setaddress2] = useState('')
  const [vat, setvat] = useState('')
  const [email, setemail] = useState('')
  const [nome, setnome] = useState('')
  const [cognome, setcognome] = useState('')
  const [zip, setzip] = useState('')
  const [datanascita, setdatanascita] = useState('')
  const [sesso, setsesso] = useState('')
  const [city, setcity] = useState('')
  const [telefono, settelefono] = useState('')
  const [nazione, setnazione] = useState('')
  const [visible, setvisible] = useState('')
  const [msg, setmsg] = useState('')

  const dispatch = useDispatch();

   const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)

  const onLoginStart = useCallback(() => {
    console.log('login start')
  }, [])

  const onLogoutSuccess = useCallback(() => {
    dispatch(setEmailLoggato(null))
    alert('logout success')
  }, [])

  function confermaSocialLogin(emailsocial, nomesocial, cognomesocial, telefonosocial){
    async function sociallogin() {
      if (emailsocial){
        const token = await gettoken(emailsocial,null)
        try{
          const utente=await insutente(emailsocial, nomesocial, cognomesocial, telefonosocial, token)
          if (utente&&utente.id){
            dispatch(setanagrafica(null))
            localStorage.setItem('idanag',null);
              
            dispatch(setEmailLoggato(emailsocial));
            localStorage.setItem("email",emailsocial)
            localStorage.removeItem("emaildaconfermare")
            window.location.href='/'
          }
        }
        catch(err){
          console.log(err)
          alert(err.response.data.message)
        }
      }
      else {
        alert('No email specified after social login');
      }
    }
    sociallogin()
  }

  function handleSubmit(event) {
    async function inserisciute(){
      event.preventDefault();
      if (!email.trim()){
        alert('Email obbligatoria');
        return
      }
      if (!nome.trim()){
        alert('Nome obbligatorio');
        return
      }
      if (!cognome.trim()){
        alert('Cognome obbligatorio');
        return
      }
      if (!telefono.trim()){
        alert('Telefono obbligatorio');
        return
      }
      try{
      const result=await insutente(email, nome, cognome, telefono)
       console.log(result);
        if (result&&result.id&&result.id>0){
          alert('Utente registrato correttamente')
          window.location.href="/"
        }
      }
      catch(err){
        console.log(err)
        alert(err.response.data.message)
      }
      
    }
    inserisciute();
  }

	    return (
	    <div className="container">
        <h4>Registrazione</h4>
        Reegistati utilizzando il tuo social
<LoginSocialFacebook
          appId="410867880057409"
          fieldsProfile={
            'first_name,last_name,middle_name,name,email'
          }
          onLoginStart={onLoginStart}
          onLogoutSuccess={onLogoutSuccess}
          redirect_uri="https://annotatio.online/"
          onResolve={( provider, data ) => {
            console.log(provider.provider,"provider")
            console.log(provider.data, "data")
            confermaSocialLogin(provider.data.email, provider.data.first_name, provider.data.last_name, '');
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <FacebookLoginButton />
        </LoginSocialFacebook>

        <LoginSocialGoogle
          client_id="1008305227109-rahajufb101r59qvk7a8np7jh9hpq3mi.apps.googleusercontent.com"
          onLoginStart={onLoginStart}
scope="https://www.googleapis.com/auth/userinfo.profile"
          onResolve={( provider, data ) => {
            console.log(provider.provider,"provider")
            console.log(provider.data, "data")
            confermaSocialLogin(provider.data.email, provider.data.first_name, provider.data.last_name, '');
            
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <GoogleLoginButton />
        </LoginSocialGoogle>

            <hr />
            Oppure manualmente
            <hr />
        <div className="form-row">

          <div className="col-md-4">
          <div class="form-group">
          <label>Email</label>
            <input type="text" className="form-control" placeholder="email" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
          </div>
          </div>
          <div Name="col-md-4">
          <div class="form-group">
          <label>Telefono</label>
            <input type="text"placeholder="TELEFONO" className="form-control"  name="telefono" value={telefono} onChange={(e) => settelefono(e.target.value)} />
          </div>
          </div>
         </div>
        <div className="form-row">
          <div className="col-md-4">
          <div class="form-group">
          <label>Nome</label>
            <input type="text" className="form-control" placeholder="NOME" name="nome" value={nome} onChange={(e) => setnome(e.target.value)} />
          </div>
          </div>
          <div className="col-md-4">
          <div class="form-group">
          <label>Cognome</label>
            <input type="text" className="form-control" placeholder="COGNOME" name="cognome" value={cognome} onChange={(e) => setcognome(e.target.value)} />
          </div>
          </div>
          
        </div>
        
         <button onClick={handleSubmit}>SALVA</button>
      </div>
        )
}