import React from "react";
import { useSelector } from "react-redux";

export default function LoginUtil(){

    const email = useSelector(state => state.gestoreUtente.emailloggato);

    function logout(){
        localStorage.clear();
        window.location.href="/"
    }

    return (
        <div>
            {
                (localStorage.getItem('email')||email)
                &&
                <div>
                <span>{localStorage.getItem('email')?localStorage.getItem('email'):email}</span>
                <span><button onClick={logout}>Logout</button></span>
                </div>
            }
            {
             !localStorage.getItem('email')&&!email&&
                <div>
                    <span><a href="/loginannotatio">Login</a></span> - 
                    <span><a href="/annotatiou-register">Register</a></span>
                </div>
            }

        </div>
    )
}