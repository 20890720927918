import React from "react";
import axios from 'axios';
import '../util/config'
import RigaCalendario from "./RigaCalendario";

export default class RigaRicercaPalestra extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            utentebbonato:false
        };
        this.goToRegistration=this.goToRegistration.bind(this)
    }
      componentDidMount(){
        if (this.props.email){
            const formData = new FormData();
            formData.append('email',this.props.email);
            formData.append('palestra',this.props.palestra.id);
          
            axios.post(global.config.server+"/isAnagraficaIscritta", formData)
            .then(response=>{
              console.log(response);
              this.setState({utentebbonato:response.data})
            })
        }
      }

    goToRegistration(){
        localStorage.setItem("palassociata",this.props.palestra.id)
        window.location.href=this.props.link2
    }

render() {

    return (
<div className="card">
    <div className="card-horizontal">
        <div className="card-body" style={{margin:0}}>

            <img className="img img-responsive full-width rounded float-left" width="100em" src={this.props.palestra.logo} alt="Card image cap" />
            <h4 className="card-title">{this.props.palestra.palestra}</h4>
            <p className="card-text">{this.props.palestra.descrizioneattivita}</p>
            {this.props.palestra.calendari&&this.props.palestra.calendari.length>0?
                this.props.palestra.calendari.map(calendario=>(
                    <RigaCalendario link={this.props.link} calendario={calendario} palestra={this.props.palestra.id} />
                ))
            :
                <RigaCalendario link={this.props.link} calendario={"Prenota"} />
            }
            {
                    this.props.email?
                        this.state.utentebbonato?
                            <span>Già registrato, ma è possibile 
                                <a href="#" onClick={this.goToRegistration} className="btn btn-primary">
                                registrare una nuova anagrafica
                                </a>
                            </span>
                        :
                            <a href="#" onClick={this.goToRegistration} className="btn btn-primary">
                                Registrati a {this.props.palestra.palestra}
                            </a>
                    :
                        <a href="/?login" className="btn btn-primary">Accedi per registrarti</a>
            }
        </div>
    </div>
</div>
);
  }
}