import React from "react";
import '../util/config'
import { Button } from "react-bootstrap";

export default class RigaCalendario extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 

      };
      this.openCalendario = this.openCalendario.bind(this);
    }

    componentDidMount(){
	}

    openCalendario(){
       
      localStorage.setItem("calendario",this.props.calendario);
      localStorage.setItem("palestracalendario",this.props.palestra)
      if (this.props.setcalendario){
        this.props.setcalendario(this.props.calendario) 
      }
      if (this.props.link){
        window.location.href=this.props.link
      }
      }
  
    componentDidCatch(error, info) {
          // You can also log the error to an error reporting service
          console.log(error, info);
        }
  


    render() {
      return (
        <Button onClick={this.openCalendario} style={{backgroundColor:this.props.calendario===localStorage.getItem('calendario')?'green':'blue'}}>{this.props.calendario}</Button>
        );
    }
  }