import axios from 'axios';
import serverInfo from '../util/globals';

export async function insutente(email, nome, cognome, telefono, token) {
    const formData = new FormData();
      formData.append('id',0);
      formData.append('email', email);
      
      formData.append('nome', nome);//ok
      formData.append('cognome', cognome);//ok
      formData.append('telefono',telefono);//ok

      axios.post(global.config.server+'/insUser', formData )
      const response = await axios.post(serverInfo.namesec+'/wp/insUser',
      formData,
      {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+token
        },
      },
    );
    return response.data;
}