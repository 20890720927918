import React from "react";
import axios from 'axios';
import '../util/config'

export default class NuovaPalestra extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { 
		    email:localStorage.getItem('email'),
            lic:'',
            palestra:'',
            descrizione:'',
            logo:'',
            indirizzo:'',
            citta:'',
            cap:'',
            piva:'',
            telefono:'',
            emailpalestra:'',
            gpscoords:'',
            websitepalestra:'',
            subdomain:'',
            nazione:'',
        };
        this.update=this.update.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
      }

    update(e) {
	  this.setState({ [e.target.name]: e.target.value, visible: true });
    }

      componentDidMount(){
        
      }

      handleSubmit(event){
		event.preventDefault();

		const formData = new FormData();
		formData.append('email',localStorage.getItem('email'));
		formData.append('lic',this.state.palestra);
		formData.append('palestra', this.state.palestra);//ok
		formData.append('descrizione', this.state.descrizioneattivita);//ok
        formData.append('logo', this.state.logo);//ok
        formData.append('indirizzo', this.state.indirizzo);//ok
        formData.append('citta', this.state.citta);//ok
        formData.append('cap', this.state.cap);//ok
        formData.append('piva', this.state.piva);//ok
        formData.append('telefono', this.state.telefono);//ok
        formData.append('emailpalestra', this.state.email);//ok
        formData.append('gpscoords', this.state.gpscoords);//ok
        formData.append('websitepalestra', this.state.website);//ok
        formData.append('subdomain', this.state.subdomain);
        formData.append('nazione', this.state.nazione);//ok
        formData.append('urlIcon', this.state.urlIcon?this.state.urlIcon:'');//ok
        formData.append('urlBadge', this.state.urlBadge?this.state.urlBadge:'');//ok
      axios.post(global.config.server+'/nuovaPalestra', formData)
        .then((result) => {
			console.log(result.data)
        	
            localStorage.setItem("palselezionata", result.data.id)
            localStorage.setItem('palestra',result.data.palestra)
            localStorage.setItem('descrizioneattivita',result.data.descrizioneattivita)
            localStorage.setItem('logo',result.data.logo)
            localStorage.removeItem('objanagrafica')
            localStorage.removeItem('palassociata')
            alert("Complimenti! Hai inserito con successo "+result.data.palestra);
            window.location.href=this.props.link
        })
        .catch(err => {
            if (err.response){
              alert(err.response.data.message);
            }
            else{
              alert(err.response);
            }
      });
	  }

render() {
    return (
        <form method="POST" onSubmit={this.handleSubmit} >
	        <div className="row">
			
			<div className="col-md">
				<div className="form-group">
            		<label htmlFor="palestra">Nome</label>
              		<input type="text" className="form-control" 
                      placeholder="Nome *" id="palestra" 
                      name="palestra" 
                      value={this.state.palestra} 
                      required="true" onChange={this.update} />
            	</div>
	        </div>
	        <div className="col-md">
				<div className="form-group">
            		<label htmlFor="palestra">Sottodominio</label>
              		<input type="text" className="form-control" 
                      placeholder="Sottodominio" 
                      id="sottodominio" 
                      name="subdomain" 
                      value={this.state.subdomain}
                      required="true" onChange={this.update} />
            	</div>
	        </div>
	        <div className="col-md">
	            	<div className="form-group">
	            	<label htmlFor="piva">Partita Iva</label>
	            		<input type="text" className="form-control" 
                        placeholder="Partita IVA *" 
                        id="piva" 
                        name="piva" 
                        value={this.state.piva}
                        required="true" onChange={this.update} />
	            	</div>
	        </div>
	        <div className="col-md">
	            	<div className="form-group">
	            	<label htmlFor="descr">Descrizione attività</label>
	            		<input type="text" className="form-control" 
                        placeholder="Descrizione attività" 
                        id="descr" 
                        name="descrizioneattivita"  
                        value={this.state.descrizioneattivita} required="true" onChange={this.update} />
	            	</div>
	        </div>
	        <div className="w-100"></div>
	        
	        <div className="w-100"></div>
	        <div className="col-md">
			        <div className="form-group">
		            	<label htmlFor="indirizzo">Indirizzo</label>
		            		<input type="text" className="form-control" 
                            placeholder="Indirizzo *" 
                            id="indirizzo" 
                            name="indirizzo"  
                            value={this.state.indirizzo}
                            required="true" onChange={this.update} />
		            	</div>
		   </div>
	        <div className="col-md">
		            	<div className="form-group">
		            	<label htmlFor="citta">Città</label>
		            		<input type="text" className="form-control" 
                            placeholder="Città *" 
                            name="citta" 
                            id="citta" 
                            value={this.state.citta} required="true" onChange={this.update} />
		            	</div>
	       </div>
	       <div className="col-md">
		            	<div className="form-group">
		            	<label htmlFor="cap">Cap</label>
		            		<input type="text" className="form-control" 
                            placeholder="CAP *" 
                            name="cap" id="cap" 
                            value={this.state.cap} required="true" onChange={this.update} />
		            	</div>
	       </div>
	        <div className="col-md">
		            	<div className="form-group">
		            	<label htmlFor="nazione">Nazione</label>
		            		<input type="text" className="form-control" 
                            placeholder="nazione *" 
                            name="nazione" id="nazione" 
                            value={this.state.nazione} required="true" onChange={this.update} />
		            	</div>
	       </div>
	       <div className="w-100"></div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="tel">Telefono</label>
			              <input type="text" className="form-control" 
                          placeholder="tel *" 
                          name="telefono" id="telefono" 
                          value={this.state.telefono} required="true" onChange={this.update} />
			            </div>
			</div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Email</label>
			              <input type="text" className="form-control" 
                          placeholder="email *" 
                          name="email" id="email" 
                          value={this.state.email} required="true" onChange={this.update} />
			            </div>
			</div>
			<div className="w-100"></div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Coordinate gps</label>
			              <input type="text" className="form-control" 
                          placeholder="stringa ricerca google maps" 
                          name="gpscoords" id="gpscoords" 
                          value={this.state.gpscoords} onChange={this.update} />
			            </div>
			</div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Sito web</label>
			              <input type="text" className="form-control" 
                          placeholder="sito web" 
                          name="website" id="website" 
                          value={this.state.website} onChange={this.update} />
			            </div>
			</div>

	    </div>
	    <div className="row">
            <div className="form-group">
	            <img src={this.state.logo} alt="logo" width="100" /><br />
	              Link Immagine: <input type="text" name="logo" value={this.state.logo} onChange={this.update} />
            </div>
            <div className="form-group">
	            <img src={this.state.urlIcon} alt="logo" width="100" /><br />
	              Link icona principale notifiche (32x32): <input type="text" name="urlIcon" value={this.state.urlIcon} onChange={this.update} />
            </div>
            <div className="form-group">
	            <img src={this.state.urlBadge} alt="logo" width="100" /><br />
	              Link icona secondaria notifiche: <input type="text" name="urlIcon" value={this.state.urlBadge} onChange={this.update} />
	              <br /><br /><br />
            </div>
            <div className="form-group text-center">
              <input type="submit" className="btnSubmit" value="Salva" />
            </div>
       </div>
        </form>
        );
  }
}