import React from "react";
import axios from 'axios';
import '../util/config'
import RigaRicercaPalestra from "../palestra/RigaRicercaPalestra";

export default class RiquadroRicercaAttivita extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        palestre:[],
        ricerca:''
        };
        this.update = this.update.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    
    }

    componentDidMount(){
        this.setState({'ricercaattivita':localStorage.getItem('ricerca')});
	  }
  
    componentDidCatch(error, info) {
          console.log(error, info);
        }
  
    update(e) {
        this.setState({ [e.target.name]: e.target.value});
    }

    handleSubmit(event){
		event.preventDefault();
          const formData = new FormData();
          formData.append('commerciante',this.state.ricercaattivita);
          axios.post(global.config.server+"/cercaCommercianti", formData)
            .then(response=>{
              console.log(response);
              this.setState({palestre:response.data})
          })
	  }

    render() {
      return (
        <section className="elementor-section elementor-top-section elementor-element elementor-element-072c904 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="072c904" data-element_type="section">
        <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7055e33" data-id="7055e33" data-element_type="column">
            <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-be28ab9 elementor-button-align-stretch elementor-widget elementor-widget-form" data-id="be28ab9" data-element_type="widget" data-settings="{&quot;step_next_label&quot;:&quot;Next&quot;,&quot;step_previous_label&quot;:&quot;Previous&quot;,&quot;button_width&quot;:&quot;100&quot;,&quot;step_type&quot;:&quot;number_text&quot;,&quot;step_icon_shape&quot;:&quot;circle&quot;}" data-widget_type="form.default">
                    <div className="elementor-widget-container">
                    <form className="elementor-form" method="post" name="New Form" onSubmit={this.handleSubmit}>

                        <div className="elementor-form-fields-wrapper elementor-labels-above">
                              <div className="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-100 elementor-field-required">
                                <label htmlFor="form-field-name" className="elementor-field-label">Prenota un'attività commerciale</label>

                                        <input  type="text" name="ricercaattivita" id="form-field-name" 
                                        className="elementor-field elementor-size-sm  elementor-field-textual" 
                                        placeholder="attività commerciale" required="required" aria-required="true" 
                                        onChange={this.update}/>
                            </div>
                <div className="elementor-field-group elementor-column elementor-field-type-submit elementor-col-100 e-form__buttons">
                    <button className="elementor-button elementor-size-sm">
                        <span>
                            <span className="elementor-align-icon-left elementor-button-icon">
                                <i aria-hidden="true" className="fas fa-search"></i>
                            </span>
                            <span className="elementor-button-text">Cerca</span>
                        </span>
                    </button>
                </div>
                </div>
                </form>
</div>
</div>
    </div>
</div>
            </div>
            <div>
                {this.state.palestre
                &&this.state.palestre.length>0?
                <span>
                    {this.state.palestre.map(palestra=>(
                       <div className="card">
                       <div className="card-horizontal">
                           <div className="card-body" style={{margin:0}}>
                               <RigaRicercaPalestra palestra={palestra} email={this.props.email} link={this.props.link} link2={this.props.link2} />
                           </div>
                       </div>
                        </div>
                        ))}
                </span>
            :"Nessuna attività trovata"}
            </div>
</section>
        );
    }
  }
